// import logo from '../images/logo.svg'

function HomePage() {
  return (
    <div className="flex justify-center">
      <h1 className="text-6xl">takahiroyte.com</h1>
    </div>
  )
}

export default HomePage
