import { Link } from 'react-router-dom'

function Navbar() {
  return (
    <header>
      <nav className="bg-white text-gray-900 p-4 flex justify-center">
        <ul className="flex space-x-4">
          <li>
            <Link to="/" className="hover:bg-gray-200 px-3 py-2 rounded">
              HOME
            </Link>
          </li>
          <li>
            <Link to="/about" className="hover:bg-gray-200 px-3 py-2 rounded">
              ABOUT
            </Link>
          </li>
          <li>
            <Link to="/blog" className="hover:bg-gray-200 px-3 py-2 rounded">
              BLOG
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Navbar
