function AboutPage() {
  return (
    <div className="flex justify-center">
      <ul>
        <li>Name: Takahiro Ito</li>
        <li>ID: TakahiRoyte</li>
        <li>Job: Agile Coach</li>
      </ul>
    </div>
  )
}

export default AboutPage
