function BlogPage() {
  return (
    <div className="flex justify-center">
      <div>
        <a href="https://note.com/takahiroyte">note</a>
      </div>
    </div>
  )
}

export default BlogPage
